import React, { Component } from 'react'
import { Global } from '@emotion/core'
import styled from '@emotion/styled'

import Layout from '../../../../components/Layout'
import { globalForModal, OfferContainer } from '../../../../styles'
import createOffer from '../../../../utils/createOffer'
import addCTAListeners, { requestUpush } from '../../../../utils/addCTAListeners'

const querystring = require('querystring')

const PaydayOfferContainer = styled(OfferContainer)`
  .headline {
    font-family: inherit;
    text-transform: none;
    line-height: 1;
    margin: 0 0 20px;
    color: #059cb9;
    font-weight: bold;
    font-size: 1rem;
    text-align: left;
    letter-spacing: 0.5px;
  }
  .buttonAnchor {
    font-size: 1rem;
  }
`

class PayDay extends Component {
  componentDidMount() {
    requestUpush(['offer-lending'])
    const target = document.querySelectorAll('#offersTarget')
    const config = {childList: true}
    const observer = new MutationObserver((e) => {
      e[0].target.querySelectorAll('a').forEach((node) => {
        addCTAListeners(node, ['offer-lending'])
      })
    })
    target.forEach((node) => {
      observer.observe(node, config)
    })
    createOffer(querystring.parse(window.location.search.split('?')[1]))
  }

  render() {
    return (
      <React.Fragment>
        <Global styles={globalForModal} />
        <Layout offerPage>
          <PaydayOfferContainer >
            <h1>Congratulations!</h1>
            <h2>You Can Select More Than One Option Below</h2>
            <div id="offersTarget" />
          </PaydayOfferContainer >
        </Layout>
      </React.Fragment>
    )
  }
}
export default PayDay
